import Rails from '@rails/ujs';
import consumer from "../.././channels/consumer"
import ChatMessageService from "./chat_message_service"
import NotificationService from "../notification_service";
import phraseSelectionAlert from "../../contents/phrase_selection_alert";

class ChatService {
  constructor(
    chatId,
    chatOptions,
    chatElementContainer,
    chatElement,
    chatControls,
    chatForm,
    chatInput,
    chatTopicCategory,
    chatSubtopicCategory,
    chatTopic,
    chatSubmitBtn,
    popoverService,
    translationService,
    audioPlayerService,
    chatTopicService,
    textCorrectionService,
    correctionExplanationService,
    chatTranslationService,
    alternativeResponseService,
    suggestedReplyService,
    slowAudioService,
    translatorModalService,
    feedbackModalService
    ) {

    this.popoverService = popoverService;
    this.translationService = translationService;
    this.audioPlayerService = audioPlayerService;
    this.chatTopicService = chatTopicService;
    this.textCorrectionService = textCorrectionService;
    this.correctionExplanationService = correctionExplanationService;
    this.chatTranslationService = chatTranslationService;
    this.alternativeResponseService = alternativeResponseService;
    this.suggestedReplyService = suggestedReplyService;
    this.slowAudioService = slowAudioService;
    this.translatorModalService = translatorModalService;
    this.feedbackModalService = feedbackModalService;

    this.chatOptions = chatOptions;
    this.language = chatOptions.language;
    this.languageCode = chatOptions.languageCode;
    this.dialectCode = chatOptions.dialectCode

    this.chatId = chatId;
    this.chatElementContainer = chatElementContainer;
    this.chatElement = chatElement;
    this.chatControls = chatControls;
    this.chatForm = chatForm;
    this.chatInput = chatInput;
    this.chatTopicCategory = chatTopicCategory;
    this.chatSubtopicCategory = chatSubtopicCategory;
    this.chatTopic = chatTopic;

    this.chatSubmitBtn = chatSubmitBtn;
    this.chatSubmitBtn.disabled = true;

    this.suggestionBtn = document.getElementById('js-chat-suggested-reply-btn');
    this.suggestions = {}

    this.chatMessageService = new ChatMessageService();
    this.chatMessageService.audioPlayerService = this.audioPlayerService;

    this.isSelecting = false;
    this.isDragging = false;

    this.setupEventListeners();
    this.startChatBtn = document.querySelector('.js-start-chat-btn');

    if (this.chatOptions.messageCount === '0') {
      // we need this to get the user to interact with the chat so the first message audio can be played
      if (this.startChatBtn === null || this.startChatBtn.length === 0) {
        this.startChat();
      }
    }

    this.isLoadingMessage = false;
    this.isRecording = false;
    this.currentActive = null;
    this.lastMessage = null; // Store the last message
    this.debug = this.chatOptions.debug;
  }

  init() {
    this.setupChatChannel();
    this.setupNotificationChannel();
    this.setupTopicListeners();
    this.setupFormSubmit();
    this.setupEventListeners();

    if (window.innerWidth < 768) {
      this.chatInput.scrollIntoView({ behavior: 'smooth' });
      this.scrollToBottom();
    }
  }

  setupChatChannel() {
    const that = this;

    consumer.subscriptions.create({ channel: "ChatChannel", chat_id: that.chatId }, {
      received(data) {

        if (data['error']) {
          if (data['error_type'] === 'message_limit_reached') {
            that.removeChatLoader(that.chatElement);
            const modal = data['data'];
            if (modal) {
              that.chatControls.remove();
              const modalWithClass = modal.replace('<div class="modal fade"', '<div class="modal fade chat-message-limit-modal"');
              $('body').append(modalWithClass);
              $('.chat-message-limit-modal').modal('show');
            }
          } else {
            that.chatElement.insertAdjacentHTML('beforeend', `
              <div class="alert alert-danger js-error-message font-weight-bold" role="alert">
                AI provider error. Try <a href='javascript:void(0);' class='js-switch-model p-0'>switching AI model</a> & refreshing the page. If errors persist, <a href='/langua/communicate'>start a new conversation</a> & check our <a href='https://support.languatalk.com/article/148-im-experiencing-issues-with-the-ai-conversation-feature-communicate-what-should-i-do#I-am-seeing-error-messages-after-I-reply-de9L8' target='_blank'>help doc</a>.
              </div>
            `);

            that.removeChatLoader(that.chatElement);

            const switchButton = that.chatElement.querySelector('.js-switch-model');
            if (switchButton) {
              switchButton.addEventListener('click', () => that.switchModel());
            }
          }

          return;
        } else if (data['type'] === 'user_chat_message') {

          // don't show message if first message is 'Hi' and loading message is still showing
          if (that.isLoadingMessage && data['message']['full_message'] === 'Hi') {
            return;
          }

          // append the new message to the chat
          $('.chat-content .chat-message:last').after(data['message']['data']);

          // setup auto correction for the new message
          if (that.chatOptions.autoCorrect && data['message']['full_message'] !== 'Hi') {
            const containerEl = $('.chat-content').find(`#chat-message-${data['message']['chat_message_id']}`);
            let correctionText = containerEl.next().find('.chat-message-content').data('correction');
            if (containerEl) {
              that.handleChatCorrection(containerEl, correctionText)
              that.scrollToBottom();
            }
          } else {
            that.scrollToBottom();
          }

        } else if (data['type'] === 'translation') {
          // find the chat message with the id and update the content
          const containerEl = $('.chat-content').find(`#chat-message-${data['chat_message_id']}`)[0];
          that.handleTranslation(containerEl, data);
        } else if (data['type'] === 'suggested_reply') {

          if (data['suggestions']['error']) {
            console.log(data['suggestions']['error']);
            that.chatElement.insertAdjacentHTML('beforeend', `
              <div class="alert alert-danger js-error-message font-weight-bold" role="alert">
                ${data['suggestions']['error']} If errors persist, email support@languatalk.com.
              </div>
            `);
            that.suggestionBtn.classList.remove('pulse');
            that.suggestionBtn.disabled = false;
          } else {
            const lastChatMessage = $('.chat-content .chat-message:not(.self):last');
            that.suggestions[lastChatMessage.data('chat-message-id')] = data
            that.handleSuggestedReply(data);
          }
        } else if (data['type'] === 'correction') {
          // find the chat message with the id and update the content
          const containerEl = $('.chat-content').find(`#chat-message-${data['chat_message_id']}`);
          that.handleChatCorrection(containerEl, data);
        } else if (data['type'] === 'correction_explanation') {
          const containerEl = $('.chat-content').find(`#chat-message-${data['chat_message_id']}`);
          that.handleCorrectionExplanation(containerEl, data['text']);
        } else if (data['type'] === 'alternative_response') {
          const containerEl = $('.chat-content').find(`#chat-message-${data['chat_message_id']}`);
          that.handleAlternativeResponse(containerEl, data['text']);
        } else if (data['type'] === 'slow_audio') {
          let containerEl, slowAudioEl;
          const audioUrl = data['audio_url'];
          
          if (data['chat_message_id']) {
            containerEl = $('.chat-content').find(`#chat-message-${data['chat_message_id']}`);
            slowAudioEl = containerEl.find('.js-slow-audio-btn')[0];
          } else {
            slowAudioEl = document.querySelector('.js-slow-audio-btn loading');
          }

          if (!slowAudioEl) {
            return;
          }

          slowAudioEl.dataset.audioUrl = audioUrl
          that.handleSlowAudio(slowAudioEl, slowAudioEl.dataset.normalSpeedAudioUrl, audioUrl);
        } else if (data['type'] === 'audio') {
          that.chatMessageService.processAudio(data['audio']);
        } else if (data['type'] === 'final') {
          that.chatMessageService.updateAudioReceived();
        } else {
          that.scrollToBottom();
          // scroll so chat input is visible
          // if on mobile
          if (window.innerWidth < 768) {
            that.chatInput.scrollIntoView({ behavior: 'smooth' });
          }

          that.chatMessageService.chatElement = that.chatElement;
          if (that.chatOptions.mode === 'text_only') {
            that.chatMessageService.textOnly = true;
          }
          that.chatMessageService.addMessage(data['message']);
          that.chatMessageService.chatMessageId = data['message']['chat_message_id'];

          if (data['debug']) {
            document.querySelector('.chat-debug-container').innerHTML = data['debug']['data'];
          }
        }
      }
    });
  }

  setupNotificationChannel() {
    consumer.subscriptions.create({ channel: "NotificationChannel"}, {
      received(data) {
        let message = data["notification_data"]["message"];
        let notificationType = data["notification_data"]["notification_type"];

        const notification = new NotificationService({
          message: message,
          notificationType: notificationType
        })

        notification.show();
      }
    });
  };

  setupTopicListeners() {
    const that = this;

    // Event listener for main category buttons (e.g., 'Roleplay')
    $(document).on('click', '.js-main-chat-option-btn', function(event) {
      event.preventDefault();

      const topicCategory = $(this).data('topic-category');
      const subOptionsContainer = $('.js-sub-options-container');

      let categories = $(this).data('topics');
      if (topicCategory === 'general') {
        categories = [...categories];
      }

      that.chatTopicService.showSubCategories(topicCategory, categories, subOptionsContainer);
    });

    // Event listener for subcategory buttons within a main category
    $(document).on('click', '.js-sub-category-btn', function(event) {
      event.preventDefault();

      const topicCategory = $(this).data('topic-category');
      const topicsData = $(this).data('topics');
      const subtopicCategory = $(this).data('subtopic-category');
      const vocabSubtopic = (topicCategory === 'vocab' && subtopicCategory === 'practice_my_vocab_blend_into_chat') ||
          (topicCategory === 'vocab' && subtopicCategory === 'practice_my_vocab_word_by_word');
      const chatAboutAnythingSubtopic = (topicCategory === 'chat_about_anything' && subtopicCategory === 'my_life');

      if (topicCategory === 'my_interests' || subtopicCategory === 'my_interests') {
        const subOptionsContainer = $('.js-sub-options-container');

        if (topicsData === "undefined") {
          that.chatTopicService.addCategoryContent('my_interests')
        } else {
          that.chatTopicService.showSubCategories(subtopicCategory, topicsData, subOptionsContainer);
        }
      } else if (topicCategory === 'roleplay' || vocabSubtopic || chatAboutAnythingSubtopic) {
        that.chatTopicService.showSubOptions(topicCategory, subtopicCategory, topicsData);
      } else {
        that.setTopic(topicCategory, '', subtopicCategory, event);
      }
    });

    // Event listener for final topic selection
    $(document).on('click', '.js-sub-chat-option-btn', function(event) {
      event.preventDefault();

      const topic = $(this).data('topic');
      const topicCategory = $(this).data('topic-category');
      const subtopicCategory = $(this).data('subtopic-category');

      that.setTopic(topicCategory, subtopicCategory, topic, event);
    });
  }

  setupFormSubmit() {
    const that = this;

    that.chatInput.addEventListener("input", function(event) {
      if (that.isRecording) {
        return;
      }

      // enable the form submit button if the input is not empty
      if (this.value.length > 0) {
        that.chatSubmitBtn.disabled = false;
        that.chatSubmitBtn.classList.remove('d-none');
      } else {
        that.chatSubmitBtn.disabled = true;
        that.chatSubmitBtn.classList.add('d-none');
      }
    });

    that.chatInput.addEventListener("keypress", function(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();

        that.handleSubmitForm(that, event);
      }
    });

    that.chatForm.addEventListener('submit', function(event) {
      event.preventDefault();
      event.stopPropagation();
      that.handleSubmitForm(that, event);
    });

    $(document).on('click', '.chat-challenge', function(event) {
      // Check if the event has already been handled
      if (event.handled !== true) {
        // Mark the event as handled
        event.handled = true;

        // toggle the challenge class to closest element with class chat-challenge
        event.preventDefault();
        event.stopPropagation();
        that.toggleChallengeClass(this);
      }
    });
  }

  setupEventListeners() {
    const that = this;

    document.addEventListener('chat:messageready', (event) => {

      if (event.handled !== true) {
        event.handled = true;
        try {
          if (that.chatOptions.mode === 'text_only') {
            that.removeChatLoader(that.chatElement);
            that.chatMessageService.showMessage();
            that.chatSubmitBtn.disabled = false;
          } else {
            that.removeChatLoader(that.chatElement);
            that.chatMessageService.showMessage();
            that.chatSubmitBtn.disabled = false;
            that.scrollToBottom();
            let player = that.chatMessageService.playAudio(that.chatOptions.voiceSpeed);
            player.on('end', () => {
              that.audioPlayerService.emitPlayEnded()
            });

          }
        } catch (error) {
          that.removeChatLoader(that.chatElement);
          that.chatSubmitBtn.disabled = false;
          that.scrollToBottom();
          that.chatElement.insertAdjacentHTML('beforeend', `<div class="alert alert-danger" role="alert">${error}</div>`);
        }
      }
    });

    $(document).on('click', '.js-start-chat-btn', function(event) {
      if (event.handled !== true) {
        // Mark the event as handled
        event.handled = true;

        that.removeStartChatContainer();
        that.startChat();
      }
    });

    $(document).on('click', '.chat-challenge', function(event) {
      // Check if the event has already been handled
      if (event.handled !== true) {
        // Mark the event as handled
        event.handled = true;

        // toggle the challenge class to closest element with class chat-challenge
        event.preventDefault();
        event.stopPropagation();
        that.toggleChallengeClass(this);
      }
    });

    $('body').on('mousedown', '.js-translatable-text', function(e) {
      that.isSelecting = true;
    });

    $('body').on('mousemove', '.js-translatable-text, .saved-word', function(e) {
      if (that.isSelecting) {
        that.isDragging = true;
      }
    });

    $('body').on('mouseup', '.js-translatable-text', function(e) {
      if (that.isDragging) {
        that.isSelecting = false;
        that.isDragging = false;

        e.stopPropagation();

        let currentPhrase = window.getSelection().toString();
        let selectedTextLength = currentPhrase.replace(/\s+/g, '').length;

        if (selectedTextLength > 0) {
          if (that.chatOptions.audioRecordingService.isRecording) {
            that.chatOptions.audioRecordingService.stopRecording();
          }
        }

        if (selectedTextLength > 0 && selectedTextLength <= 70) {
          e.preventDefault();
          e.stopPropagation();

          let currentWordEl = e.target;

          that.popoverService.showLoadingPopover(currentWordEl);
          that.translationService.translateSentence(currentPhrase, (translatedData) => {
            // Callback function to execute after translation is done

            // Show the loading popover
            that.popoverService.updatePopoverContent(currentWordEl, translatedData, true);
            currentWordEl.classList.remove('loading');
          });
        } else {
          if (selectedTextLength > 70) {
            let phraseSelectionAlert = require('./../../contents/phrase_selection_alert');

            phraseSelectionAlert();
          }
        }
      } else {
        that.isSelecting = false;
      }
    });

    $('body').on('click', '.js-translatable-text:not(.challenge)', function(e) {
      e.preventDefault();
      e.stopPropagation();

      let currentWordEl = e.target;
      let currentSentence = that.getSentenceOfWord(currentWordEl);

      if (that.chatOptions.audioRecordingService.isRecording) {
        that.chatOptions.audioRecordingService.stopRecording();
      }

      if (that.currentActive != null && that.currentActive.classList.contains('active')) {
        that.popoverService.disposePopover(that.currentActive);
      }

      that.popoverService.showLoadingPopover(currentWordEl);
      that.currentActive = currentWordEl;
      that.translationService.translateWord(currentWordEl, currentSentence, (translatedData) => {
        that.popoverService.updatePopoverContent(currentWordEl, translatedData);
        that.translatorModalService.hideModal();
        currentWordEl.classList.remove('loading');
      });
    });

    $('body').on('click', '.js-play-chat-audio-btn', function(e) {
      e.preventDefault();
      e.stopPropagation();

      if (e.handled !== true) {
        e.handled = true;

        const text = $(this).data('text');
        const language = that.chatOptions.language;
        const wordEl  = $(this).closest('.word-container')[0];
        that.playAudioHandler(wordEl, this, text, language);
      }
    });

    $('body').on('click', '.js-slow-audio-btn', function(e) {
      e.preventDefault();
      e.stopPropagation();

      if (e.handled !== true) {
        e.handled = true;
        this.classList.add('pulse')
        this.classList.add('loading');

        that.handleSlowAudio(this, this.dataset.normalSpeedAudioUrl, this.dataset.audioUrl);
      }
    });

    $('body').on('click', '.js-chat-correction-btn', function(e) {
      e.preventDefault();
      e.stopPropagation();

      if (that.chatOptions.audioRecordingService.isRecording) {
        that.chatOptions.audioRecordingService.stopRecording();
      }

      // Disable button to prevent multiple clicks
      $(this).prop('disabled', true);

      if (e.handled !== true) {
        e.handled = true;

        const containerEl = $(this).closest('.chat-message');
        let correctionText = containerEl.next().find('.chat-message-content').data('correction');

        that.handleChatCorrection(containerEl, correctionText);
      }
    });

    $('body').on('click', '.js-chat-correction-explanation-btn', function(e) {
      e.preventDefault();
      e.stopPropagation();

      if (that.chatOptions.audioRecordingService.isRecording) {
        that.chatOptions.audioRecordingService.stopRecording();
      }

      // Disable button to prevent multiple clicks
      $(this).prop('disabled', true);

      if (e.handled !== true) {
        e.handled = true;

        const containerEl = $(this).closest('.chat-message');
        let correctionExplanationText = containerEl.next().find('.chat-message-content').data('correction-explanation');
        const correctionExplanationBtn = this;
        correctionExplanationBtn.classList.add('pulse');

        that.handleCorrectionExplanation(containerEl, correctionExplanationText);
      }
    });

    $('body').on('click', '.js-chat-translation-btn', function(e) {
      e.preventDefault();
      e.stopPropagation();

      if (that.chatOptions.audioRecordingService.isRecording) {
        that.chatOptions.audioRecordingService.stopRecording();
      }

      // Disable button to prevent multiple clicks
      $(this).prop('disabled', true);

      if (e.handled !== true) {
        e.handled = true;

        const translationBtn = this;
        translationBtn.classList.add('pulse');
        const chatMessageContentEl = this.parentNode.parentNode.querySelector('.chat-message-content');
        const chatMessageId = chatMessageContentEl.dataset.chatMessageId;
        const text = this.dataset.text

        that.chatTranslationService.fetchTranslation(chatMessageId, text, that.chatOptions.language)
          .catch((error) => {
            console.log('translation error', error);
            translationBtn.remove();
          });

      }
    });

    $('body').on('click', '.js-chat-suggested-reply-btn', function(e) {
      e.preventDefault();
      e.stopPropagation();

      if (e.handled !== true) {
        e.handled = true;
        const lastChatMessage = $('.chat-content .chat-message:not(.self):not(.loading):last');
        const suggestionData = that.suggestions[lastChatMessage.data('chat-message-id')];

        if (lastChatMessage.length > 0) {
          that.audioPlayerService.emitPlayStarted();
          that.handleSuggestedReply(suggestionData);
        }
      }
    });

    $('body').on('click', '.js-chat-feedback-btn', function(e) {
      e.preventDefault();
      e.stopPropagation();

      if (e.handled !== true) {
        e.handled = true;
        that.feedbackModalService.openModal();
      }
    });

    $('body').on('click', '.js-chat-translator-btn', function(e) {
      e.preventDefault();
      e.stopPropagation();

      if (e.handled !== true) {
        e.handled = true;
        that.translatorModalService.chatService = that;
        that.translatorModalService.audioPlayerService = that.audioPlayerService;
        that.translatorModalService.openModal();

        if (that.currentActive && that.currentActive.classList.contains('active')) {
          that.popoverService.disposePopover(that.currentActive);
        }
      }
    });

    $('body').on('click', '.js-user-chat-translation-btn', function(e) {
      e.preventDefault();
      e.stopPropagation();

      if (that.chatOptions.audioRecordingService.isRecording) {
        that.chatOptions.audioRecordingService.stopRecording();
      }

      // Disable button to prevent multiple clicks
      $(this).prop('disabled', true);

      if (e.handled !== true) {
        e.handled = true;

        const translationBtn = this;
        translationBtn.classList.add('pulse');
        const chatMessageContentEl = this.parentNode.parentNode.querySelector('.chat-message-content');
        const chatMessageId = chatMessageContentEl.dataset.chatMessageId;
        const text = this.dataset.text

        that.chatTranslationService.fetchTranslation(chatMessageId, text, that.chatOptions.language)
          .catch((error) => {
            console.log('translation error', error);
            translationBtn.remove();
          });
      }
    });

    $('body').on('click', '.js-alternative-response-btn', function(e) {
      e.preventDefault();
      e.stopPropagation();

      if (that.chatOptions.audioRecordingService.isRecording) {
        that.chatOptions.audioRecordingService.stopRecording();
      }

      // Disable button to prevent multiple clicks
      $(this).prop('disabled', true);

      if (e.handled !== true) {
        e.handled = true;

        const containerEl = $(this).closest('.chat-message');
        let text = containerEl.next().find('.chat-message-content').data('alternative-response');

        that.handleAlternativeResponse(containerEl, text);
      }
    });

    $('body').on('click', '#js-chat-options-btn', function(e) {
      e.preventDefault();

      that.chatOptions.openModal();
    });
  }

  handleTranslation(chatMessageContentEl, data) {

    const translationBtn = chatMessageContentEl.querySelector('.js-user-chat-translation-btn');
    const aiTranslationBtn = chatMessageContentEl.querySelector('.js-chat-translation-btn');

    // add to closest chat-message-content
    if (data['error']) {
      console.log(data['error']);
      chatMessageContentEl.innerHTML += `<div class="alert alert-danger" role="alert">${data['error']}</div>`;
      if (translationBtn) { translationBtn.classList.remove('pulse'); };
      if (aiTranslationBtn) { aiTranslationBtn.classList.remove('pulse'); };

      $(this).prop('disabled', false);
    } else {

      if (chatMessageContentEl.querySelector('.chat-message-detail')) {
        if (data.data) {
          chatMessageContentEl.querySelector('.chat-message-detail').insertAdjacentHTML('afterend', data.data);
        }
      } else {
        chatMessageContentEl.innerHTML += data.data;
      }
      this.toggleChallengeClass(chatMessageContentEl.querySelector('.chat-translation'));

      if (translationBtn) { translationBtn.remove();}
      if (aiTranslationBtn) { aiTranslationBtn.remove();}
    }
  }

  // Function to handle chat correction
  handleChatCorrection(containerEl, data) {
    if (data && data['text']) {
      this.showCorrection(containerEl, data['text']);
      if (data['debug'] && data['prompt']) {
        this.showDebugData(containerEl, data);
      }

      const correctionBtn = containerEl.find('.js-chat-correction-btn');
      if (correctionBtn) {
        correctionBtn.remove();
      }
    } else {
      const text = containerEl.find('.js-chat-correction-btn').data('text');
      const language = this.chatOptions.language;
      const context = containerEl.prev().find('.chat-message-content').data('translated-text');
      this.correctTextHandler(containerEl, text, language, context);
    }
  }

  showCorrection(containerEl, correctionText) {
    const seeExplanationLink = '<br><button type="button" class="btn btn-link js-chat-correction-explanation-btn px-0">Check & explain</button>';
    if (correctionText == 'Perfect response.') {
      containerEl.find('.chat-message-content').append(`<div class="chat-corrections mt-4">${correctionText} ${seeExplanationLink}</div>`);
    } else {
      let escapedCorrectionText = correctionText.replace(/"/g, '\\"').replace(/'/g, '&#39;');
      let correctionTextLines = correctionText.split("\n");
      let translatableCorrectionText = correctionTextLines.map(line => {
        return line.replace(/(<del>.*?<\/del>|<b>.*?<\/b>|\S+)/g, match => {
          return `<span class="js-translatable-text js-audio-word word" style="cursor: pointer;" data-translated-text='${escapedCorrectionText}' data-language="${this.chatOptions.language}">${match}</span>`;
        });
      }).join("<br>");

      containerEl.find('.chat-message-content').append(`<div class="chat-corrections mt-4" data-translated-text='${escapedCorrectionText}'>${translatableCorrectionText} ${seeExplanationLink}</div>`);
    }
  }

  showDebugData(containerEl, debugData) {
    const debugHtml = `
      <div class="chat-debug mt-3">
        <details>
          <summary>Debug Information</summary>
          <div style="background-color: #f5f5f5; padding: 10px; border-radius: 5px;">
            <pre style="white-space: pre-wrap; background-color: #fffff; padding: 10px; border-radius: 5px;">${debugData.client_provider} ${debugData.model}</pre>
            <pre style="white-space: pre-wrap; background-color: #fffff; padding: 10px; border-radius: 5px;">${this.escapeHtml(debugData.prompt)}</pre>
          </div>
        </details>
      </div>
    `;
    containerEl.find('.chat-corrections').after(debugHtml);
  }

  escapeHtml(unsafe) {
    if (typeof unsafe !== 'string') {
      return '';
    }
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }

  // Function to handle chat correction
  handleCorrectionExplanation(containerEl, correctionExplanationText) {
    if (correctionExplanationText) {
      let escapedCorrectionText = correctionExplanationText.replace(/"/g, '\\"').replace(/'/g, '&#39;');
      let correctionTextLines = correctionExplanationText.split("\n");
      let translatableCorrectionText = correctionTextLines.map(line => line.replace(/(\S+)/g, `<span class="js-translatable-text js-audio-word word" style="cursor: pointer;" data-translated-text='${escapedCorrectionText}' data-language="${this.chatOptions.language}">$1</span>`)).join("<br>");
      containerEl.find('.chat-corrections').after(`<div class="chat-correction-explanations mb-0 pb-4" data-translated-text="${escapedCorrectionText}">${translatableCorrectionText}</div>`);
      const correctionExplanationBtn = containerEl.find('.js-chat-correction-explanation-btn');
      if (correctionExplanationBtn) {
        correctionExplanationBtn.remove();
      }
    } else {
      const correctionText = containerEl.find('.chat-corrections').data('translated-text');
      const responseText = containerEl.find('.chat-message-content').data('translated-text')
      const messageText = containerEl.prev().find('.chat-message-content').data('translated-text');
      const language = this.chatOptions.language;
      this.explanationHandler(containerEl, correctionText, messageText, responseText, language);
    }
  }

  handleAlternativeResponse(containerEl, alternativeResponseText) {
    if (alternativeResponseText) {
      let escapedAlternativeResponseText = alternativeResponseText.replace(/"/g, '\\"').replace(/'/g, '&#39;');
      let translatableAlternativeText = alternativeResponseText.replace(/(\S+)/g, `<span class="js-translatable-text js-audio-word word" style="cursor: pointer;" data-translated-text='${escapedAlternativeResponseText}' data-language="${this.chatOptions.language}">$1</span>`);
      containerEl.find('.chat-message-content').append(`<div class="chat-alternative-response mt-4" data-translated-text="${escapedAlternativeResponseText}"><span class="font-weight-bold">Alternative:</span> ${translatableAlternativeText}</div>`);
      const alternativeResponseBtn = containerEl.find('.js-alternative-response-btn');
      if (alternativeResponseBtn) {
        alternativeResponseBtn.remove();
      }
    } else {
      const responseText = containerEl.find('.chat-message-content').data('translated-text')
      const language = this.chatOptions.language;
      const context = containerEl.prev().find('.chat-message-content').data('translated-text');

      this.alternativeResponseHandler(containerEl, responseText, language, context);
    }
  }

  handleSuggestedReply(suggestionData = null) {

    const lastChatMessage = $('.chat-content .chat-message:not(.self):last');

    if (suggestionData != null && suggestionData != undefined) {
      this.suggestionBtn.classList.remove('pulse');
      this.suggestionBtn.disabled = false;
      this.suggestedReplyService.showSuggestions(this, suggestionData['suggestions'], this.chatOptions.language);
    } else {
      const responseText = lastChatMessage.find('.chat-message-content').data('translated-text')
      const language = this.chatOptions.language;
      const context = lastChatMessage.prev().find('.chat-message-content').data('translated-text');
      this.suggestionBtn.disabled = true;
      this.suggestionBtn.classList.add('pulse');

      this.suggestedReplyHandler(lastChatMessage, responseText, language, context);
    }
  }

  handleSlowAudio(slowAudioEl, audioUrl, slowAudioUrl = null) {
    if (slowAudioUrl === undefined || slowAudioUrl === null) {
      this.slowAudioService.fetch('Chat', this.chatId, audioUrl, slowAudioEl.dataset.chatMessageId);
    } else {
      const text = slowAudioEl.dataset.text;
      const wordEl  = $(slowAudioEl).closest('.word-container')[0];

      slowAudioEl.classList.remove('loading', 'pulse');

      this.playAudioHandler(wordEl, slowAudioEl, text, this.language, 0.75);
    }
  }

  // Method to play audio
  playAudioHandler(wordEl, soundEl, text, language, playbackRate = 1.0) {
    this.audioPlayerService.setupAudio(wordEl, soundEl, text, language, { voice: this.chatOptions.voice, voice_provider: this.chatOptions.voiceProvider, speed: this.chatOptions.voiceSpeed }, true, playbackRate)
  }

  correctTextHandler(element, text, language, context = null) {
    const that = this;
    const correctionBtn = element.find('.js-chat-correction-btn');
    const chatMessageId = element.data('chat-message-id');
    correctionBtn.addClass('pulse');

    that.textCorrectionService.fetchCorrection(
      that.chatId,
      chatMessageId,
      text,
      language,
      context,
      that.chatOptions.clientProvider,
      that.chatOptions.model,
      that.debug
    )
      .catch((error) => {
        correctionBtn.remove();
      });
  }

  explanationHandler(element, correctionText, messageText, responseText, language) {
    const that = this;
    const explanationBtn = element.find('.js-chat-correction-explanation-btn');
    const chatMessageId = element.data('chat-message-id');
    explanationBtn.addClass('pulse');

    that.correctionExplanationService.fetchExplanation(
      that.chatId,
      chatMessageId,
      correctionText,
      messageText,
      responseText,
      language,
      that.chatOptions.clientProvider,
      that.chatOptions.model,
    )
      .catch((error) => {
        explanationBtn.remove();
      });
  }

  alternativeResponseHandler(element, text, language, context = null) {
    const that = this;
    const alternativeResponseBtn = element.find('.js-alternative-response-btn');
    const chatMessageId = element.data('chat-message-id');
    alternativeResponseBtn.addClass('pulse');

    that.alternativeResponseService.fetch(
      that.chatId,
      chatMessageId,
      text,
      language,
      context,
      that.chatOptions.clientProvider,
      that.chatOptions.model
    )
      .catch((error) => {
        alternativeResponseBtn.remove();
      });
  }

  suggestedReplyHandler(element, text, language, context = null) {
    const that = this;
    const chatMessageId = element.data('chat-message-id');

    that.suggestedReplyService.fetch(that.chatId, chatMessageId, text, language, context) 
      .catch((error) => {
        suggestionBtn.disabled = false;
      });
  }

  toggleChallengeClass(element) {
    $(element).children().toggleClass('challenge');
  }

  sendMessage(message, event) {
    this.chatInput.value = message;
    this.handleSubmitForm(this, event);
  }

  removeStartChatContainer() {
    this.chatElementContainer.classList.remove('chat-content-w-centered');
    this.chatElementContainer.classList.add('chat-content-w-default');
    this.chatElement.classList.remove('chat-content-centered');
    this.chatElement.classList.add('chat-content-default');

    if (this.chatElement.querySelector('.js-start-chat-container')) {
      this.chatElement.querySelector('.js-start-chat-container').remove();
    }

  }

  startChat() {
    this.chatInput.value = "Hi";
    this.handleSubmitForm(this, event);
  }

  setTopic(topicCategory, chatSubtopicCategory, topic, event) {
    this.chatTopicCategory.value = topicCategory;
    this.chatSubtopicCategory.value = chatSubtopicCategory;
    this.chatTopic.value = topic;
    this.chatInput.value = "Hi";
    this.handleSubmitForm(this, event);
  }

  handleSubmitForm(that, event) {
    if (that.chatInput.value.length > 0) {
      // Store the last message
      that.lastMessage = that.chatInput.value;

      const lastChatMessageId = $('.chat-message').last().data('chat-message-id');
      const nextChatMessageId = lastChatMessageId === undefined ? 0 : parseInt(lastChatMessageId) + 1;
      let userAvatarEl = $('.chat-message-avatar.self').last().html();

      if (lastChatMessageId === undefined) {
        that.chatTopicService.hideChatTopics();
        that.chatTopicService.hideTopicMenu();
      }

      if (userAvatarEl === undefined) {
        userAvatarEl = `<img title="${that.chatForm.dataset.avatarTitle}" src="${that.chatForm.dataset.avatarPath}">`;
      }

      that.chatSubmitBtn.disabled = true;

      if (nextChatMessageId === 0) {
        if (that.chatTopicCategory.value === 'roleplay') {
          try {
            const selectedTopic = that.chatTopicService.topics.find((topic) => {
              return topic.key === that.chatTopic.value;
            }).label;

            if (selectedTopic) {
              const loadingMessage = `<strong>Your role play will load in 5-8 seconds…remember, the situation is: ${selectedTopic.toLowerCase()}</strong>.`;
              that.addChatLoader(that.chatElement, loadingMessage);
            } else {
              const loadingMessage = `Your role play will load in 5-8 seconds.`;
              that.addChatLoader(that.chatElement, loadingMessage);
            }
          } catch (error) {
            const loadingMessage = `Your role play will load in 5-8 seconds.`;
            that.addChatLoader(that.chatElement, loadingMessage);
          }
        } else {
          const firstLetterUppercase = this.language.charAt(0).toUpperCase() + this.language.slice(1);
          const loadingMessage = `The conversation will start in 4-8 seconds...get ready to speak ${firstLetterUppercase}! You’ll earn 2 points for every reply.`;
          that.addChatLoader(that.chatElement, loadingMessage);
        }

        if (this.startChatBtn) {
          this.removeStartChatContainer();
        }

      } else {
        that.addChatLoader(that.chatElement);
      }

      that.scrollToBottom();

      Rails.handleRemote.call(that.chatForm, event);

      that.chatInput.value = '';
    }
  }

  // Function to add the loader
  addChatLoader(targetElement, message = null) {
    const loader = document.createElement('div');
    loader.className = 'chat-loader mt-3';

    // display message if provided
    if (message) {
      loader.innerHTML = `
      <div class="chat-message loading">
        <div class="chat-message-content-w">
          <div class="chat-message-content">
            ${message}
          </div>
        </div>
      </div>
      `;

      for (let i = 0; i < 3; i++) {
        const dot = document.createElement('span');
        dot.className = 'chat-dot';
        loader.appendChild(dot);
      }

      this.isLoadingMessage = true;
    } else {
      for (let i = 0; i < 3; i++) {
        const dot = document.createElement('span');
        dot.className = 'chat-dot';
        loader.appendChild(dot);
      }
    }

    targetElement.appendChild(loader);

  }

  // Function to remove the loader
  removeChatLoader(targetElement) {
    const loader = targetElement.querySelector('.chat-loader');
    if (loader) {
      targetElement.removeChild(loader);
    }

    this.isLoadingMessage = false;
  }

  scrollToBottom() {
    this.chatElementContainer.scroll({ top: this.chatElementContainer.scrollHeight, behavior: 'smooth' });
    // scroll to last message
    const messages = document.querySelectorAll('.chat-message');
    const lastMessage = messages[messages.length - 1];
    if (lastMessage) {
      lastMessage.scrollIntoView({ behavior: 'smooth' });
    }
  }

  cleanSentence(sentence) {
    const cleanups = [
      // Replace multiple spaces with a single space
      [/\s+/g, ' '],

      // Handle punctuation spacing
      [/\s*([,.:;?!»)}\]°"'%‰‱])/g, '$1'],
      [/([«({\[])\s*/g, '$1'],
      [/(\S)\s*([:.;,])/g, '$1$2'],

      // Handle apostrophes
      [/(\S)\s*([''])\s*(\S)/g, '$1$2$3'],

      // Handle quotation marks
      [/"(\S)/g, '" $1'],
      [/(\S)"/g, '$1 "'],
      [/([.!?])\s*"/g, '$1"'],

      // Remove leading punctuation and spaces
      [/^\s*([^\w\s]+\s*)*/, '']
    ];

    return cleanups.reduce((text, [pattern, replacement]) =>
      text.replace(pattern, replacement), sentence).trim();
  }

  getSentenceOfWord(element) {
    let sentenceParts = [];
    let currentElement = element;
 
    const endOfSentenceRegex = /[.!?。！？។៕။…\u3002\uFF01\uFF1F\u0964\u0965]/;
    const isEndOfSentence = (text) => endOfSentenceRegex.test(text);
 
    const cleanText = (text) => {
      // Remove any HTML tags, but keep their content
      let cleanedText = text.replace(/<[^>]*>/g, '');
      // Clean up whitespace
      return cleanedText.trim().replace(/&nbsp;/g, ' ').replace(/\s+/g, ' ');
    };
 
    const filterDeletedContent = (element) => {
      let text = element.innerHTML;
      // Remove struck-out text and keep only the corrections
      text = text.replace(/<del>.*?<\/del>\s*<b>(.*?)<\/b>/g, '$1');
      // Remove any remaining <del> tags and their content
      text = text.replace(/<del>.*?<\/del>/g, '');
      return text;
    };
 
    // Collect words and punctuation
    const collectParts = (direction) => {
      let reachedSentenceEnd = false;
      while (currentElement && (direction === 'left' ? currentElement.previousElementSibling : currentElement.nextElementSibling)) {
        currentElement = direction === 'left' ? currentElement.previousElementSibling : currentElement.nextElementSibling;
        let text = filterDeletedContent(currentElement);
        if (text) {
          if (direction === 'left') {
            if (isEndOfSentence(text)) {
              reachedSentenceEnd = true;
              break;
            }
            sentenceParts.unshift(text);
          } else {
            sentenceParts.push(text);
            if (isEndOfSentence(text)) {
              reachedSentenceEnd = true;
              break;
            }
          }
        }
      }
      return reachedSentenceEnd;
    };
 
    // Collect parts to the left
    collectParts('left');
 
    // Add the current word
    sentenceParts.push(filterDeletedContent(element));
 
    // Reset currentElement to the clicked word
    currentElement = element;
 
    // Collect parts to the right, but stop at the end of the sentence
    let reachedEnd = collectParts('right');
 
    // If we reached the end of the sentence, remove the last word (which is part of the next sentence)
    if (reachedEnd) {
      sentenceParts.pop();
    }
 
    // Join the sentence parts and clean up
    let sentence = sentenceParts.join(' ');
    return this.cleanSentence(cleanText(sentence));
  }

  getAlternativeModel() {
    return this.chatOptions.aiModel === 'v1' ? 'V2' : 'V1';
  }

  getCurrentModel() {
    return this.chatOptions.aiModel === 'v1' ? 'V1' : 'V2';
  }

  switchModel() {
    let newAIModel, newProvider, newModel;
    if (this.chatOptions.aiModel === 'v1') {
      newAIModel = 'v2';
      newProvider = 'openai';
      newModel = 'gpt-4o-2024-11-20';
    } else {
      newAIModel = 'v1';
      newProvider = 'anthropic';
      newModel = 'claude-3-5-sonnet-latest';
    }

    this.chatOptions.updateClientProviderAndModel(newAIModel, newProvider, newModel);
    
    // Remove the error message
    const errorMessage = this.chatElement.querySelector('.js-error-message');
    if (errorMessage) {
      errorMessage.remove();
    }

    // Add a success message
    this.chatElement.insertAdjacentHTML('beforeend', `
      <div class="alert alert-info js-success-message" role="alert">
        Switched to ${this.getCurrentModel()}. You can continue your conversation.
      </div>
    `);

    this.scrollToBottom();

    // Remove the success message after 5 seconds
    setTimeout(() => {
      const successMessage = this.chatElement.querySelector('.js-success-message');
      if (successMessage) {
        successMessage.remove();
      }
    }, 5000);

    // Resend the last message if it exists
    if (this.lastMessage) {
      this.chatInput.value = this.lastMessage;
      this.handleSubmitForm(this, event);
    }
  }
}

export default ChatService;
