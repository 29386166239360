import Rails from '@rails/ujs';

document.addEventListener('turbolinks:load', function() {
  const podcastSearchForm = document.getElementById('podcast-filter');
  const podcastEpisodeSearchForm = document.getElementById('show-podcast-filter');

  if (podcastSearchForm) {

    const $podcastSearchForm = $(podcastSearchForm);
    const formAction = $podcastSearchForm.attr('action');
    const searchInput = document.getElementById('js-search-podcast');
    const dialectsFilter = document.getElementById('js-filter-dialects');
    const levelsFilter = document.getElementById('js-filter-levels');
    const languageFilter = document.getElementById('js-language-select');
    const showViewedBtn = document.getElementById('js-show-viewed-episodes');
    const hideViewedBtn = document.getElementById('js-hide-viewed-episodes');
    let delayTimer;

    searchInput.addEventListener("input", function(event) {
      clearTimeout(delayTimer);
      delayTimer = setTimeout(function() {
        handleSubmitForm(false);
      }, 1000);
    });

    searchInput.addEventListener("keypress", function(event) {
      if (event.key === 'Enter') {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function() {
          handleSubmitForm(false);
        }, 1000);
      }
    });

    dialectsFilter.addEventListener('change', handleFilterChange);
    levelsFilter.addEventListener('change', handleFilterChange);

    languageFilter.addEventListener('change', function (evt) {
      dialectsFilter.value = undefined;
      levelsFilter.value = undefined;
      handleSubmitForm(true);
    });

    var handleSubmitForm = function(refreshPage) {
      let formData = $podcastSearchForm.serialize();
      if (refreshPage) {
        $podcastSearchForm.submit();
      } else {

        Rails.fire(podcastSearchForm, 'submit');

        let url = formAction + "?" + formData;

        history.replaceState(history.state, "", formAction + "?" + formData);
      }
    }

    function handleFilterChange() {
      searchInput.value = '';

      handleSubmitForm(false);
    }

    var resetPodcastSearchForm = function() {
      searchInput.value = '';

      if (hideViewedBtn && showViewedBtn) {
        hideViewedBtn.checked = false;
        showViewedBtn.checked = false;
      }

      handleSubmitForm(false);
    }

    $('#js-reset-form').on('click', function(e) {
      e.stopPropagation(false);
      resetPodcastSearchForm();
    });

    $('.js-language-select').on('change', function (evt) {
      handleSubmitForm(true)
    })

    $('#js-reset-form').on('click', function(e) {
        e.stopPropagation(false);
        var searchFilter = document.getElementById('podcast-filter');

        $('.js-language-select').val(undefined);
        $('.js-filter-levels').val(undefined);
        $('.js-filter-dialects').val(undefined);
        $('.js-search-podcast').val(undefined);

        $(searchFilter).submit();
    });

    // if user not logged in display sign up modal after 20 seconds
    let signupLink = document.querySelector('.js-shared-signup-modal-btn');
    let signupModal = document.getElementById('signup-modal');

    if (signupLink) {
      let timerId = setTimeout(function () {
        signupLink.click();
      }, 20000);

      $(signupModal).on('shown.bs.modal', function () {
        clearTimeout(timerId);
      });
    }
  }

  if (podcastEpisodeSearchForm) {
    const $podcastEpisodeSearchForm = $(podcastEpisodeSearchForm);
    const formAction = $podcastEpisodeSearchForm.attr('action');
    const searchInput = document.getElementById('js-search-episodes');
    let showViewedBtn = document.getElementById('js-show-viewed-episodes');
    let hideViewedBtn = document.getElementById('js-hide-viewed-episodes');
    let sortByCreationDateBtn = document.getElementById('js-sort-by-creation-date');
    let delayTimer;

    searchInput.addEventListener("input", function(event) {
      clearTimeout(delayTimer);
      delayTimer = setTimeout(function() {
        handleSubmitForm();
      }, 1000);
    });

    searchInput.addEventListener("keypress", function(event) {
      if (event.key === 'Enter') {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function() {
          handleSubmitForm();
        }, 1000);
      }
    });

    function handleSubmitForm() {
      let formData = $podcastEpisodeSearchForm.serialize();

      Rails.fire(podcastEpisodeSearchForm, 'submit');

      let url = formAction + "?" + formData;

      history.replaceState(history.state, "", formAction + "?" + formData);
    }

    if (hideViewedBtn) {
      hideViewedBtn.addEventListener('change', function (evt) {
        handleSubmitForm()
      })
    }

    if (showViewedBtn) {
      showViewedBtn.addEventListener('change', function (evt) {
        handleSubmitForm()
      })
    }

    if (sortByCreationDateBtn) {
      sortByCreationDateBtn.addEventListener('change', function (evt) {
        handleSubmitForm()
      })
    }

    var resetPodcastEpisodeSearchForm = function() {
      searchInput.value = '';
      if (showViewedBtn) {
        showViewedBtn.checked = false;
      }

      if (hideViewedBtn) {
        hideViewedBtn.checked = false;
      }

      if (sortByCreationDateBtn) {
        sortByCreationDateBtn.checked = false;
      }

      handleSubmitForm();
    }

    $('#js-reset-form').on('click', function(e) {
      e.stopPropagation(false);
      resetPodcastEpisodeSearchForm();
    });
  }
});
