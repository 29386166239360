class ChatTopicService {
  constructor(chatElementContainer, chatElement) {
    this.chatElementContainer = chatElementContainer;
    this.chatElement = chatElement;
    this.topics = null;
    this.userId = this.chatElementContainer.querySelector('.chat-content').dataset.userId;
  }

  showSubCategories(topicCategory, subCategories, container) {
    container.empty();

    subCategories.forEach(subCategory => {
      let divHtml = $(`<div class="col-12 col-md-4 col-lg-4 col-xl-3"></div>`);
      const buttonHtml = $(`<button type="button" class="btn btn-secondary-cta-outline gray-bg py-3 chat-option-button w-100 mb-2" data-topic-category="${topicCategory}" data-subtopic-category="${subCategory.key}" data-topics='${JSON.stringify(subCategory.topics === undefined ? null : subCategory.topics)}'>${subCategory.label}</button>`);

      if (subCategory.key === 'generate_random_interests_title') {
        buttonHtml.addClass('js-generate-topic-titles-btn');
      } else {
        if (subCategory.key === 'generate_your_interest') {
          buttonHtml.addClass('js-generate-interest-btn')
        }

        buttonHtml.addClass('js-sub-category-btn');
      }

      if (topicCategory === 'debate') {
        container.addClass('scroll-options');
      }

      divHtml.append(buttonHtml);
      container.append(divHtml);
    });

    $('.js-chat-form-back-button').removeClass('d-none');

    const that = this;
    $('body').on('click', '.js-chat-form-back-button', function (e) {
      // remove all js-sub-category-btn in container
      container.empty();
      $('.js-chat-form-back-button').addClass('d-none');
      that.showChatTopics();
    })

    this.hideChatTopics();
  }

  showSubOptions(topicCategory, subtopicCategory, topics) {
    this.topics = topics;
    const container = $('.js-sub-options-container');
    container.empty();

    topics.forEach(topic => {
      const buttonHtml = `<div class="col-md-4 col-lg-4 col-xl-3">
                                    <button type="button" class="btn btn-secondary-cta-outline gray-bg py-3 chat-option-button w-100 mb-2 js-sub-chat-option-btn" data-topic="${topic.key}" data-topic-category="${topicCategory}" data-subtopic-category="${subtopicCategory}">
                                        ${topic.label}
                                    </button>
                                </div>`;
      container.append(buttonHtml);
    });

    container.removeClass('d-none');
  }

  addCategoryContent(topicCategory, container, generated_topic = null) {
    container.empty();
    let containerContent = ''

    if (topicCategory === 'my_interests') {
      containerContent = `<div class="p-3">
        <strong>
          To view discussion options related to your interests, please first go to 
          <a href="/account/langua_settings/${this.userId}/edit?active_tab=chat-settings">Settings > Chat Settings</a> 
          and select some interests. Once done, you'll also be able to add custom interests here.
        </strong>
      </div>`;
    }  else if (topicCategory === 'generated_interest') {
      containerContent = `<div class="col-12 col-md-4 col-lg-4 col-xl-5">
                            <button type="button" 
                              class="btn btn-secondary-cta-outline py-3 w-100 js-generate-topic-titles-btn" 
                              data-topic-category=${JSON.stringify(generated_topic)}
                              data-subtopic-category="generate_random_interests_title"
                              data-topics='${JSON.stringify(null)}'>
                              Generate discussions about ${generated_topic}
                            </button>
                          </div>`;
    } else if (topicCategory === 'generate_your_interest') {
      containerContent = `<div class="col-12 col-md-4 col-lg-4 col-xl-5">
                            <div class="form-group mb-3">
                              <input type="text" 
                                class="form-control js-custom-interest-input dark-placeholder" 
                                maxlength="30" 
                                placeholder="Enter an interest (max 30 characters)"
                                aria-label="Custom interest input">
                              <small class="text-muted js-char-count">0/30 characters</small>
                            </div>
                            <button type="button" 
                              class="btn btn-secondary-cta-outline py-3 w-100 js-generate-topic-titles-btn" 
                              disabled
                              data-topic-category=""
                              data-subtopic-category="generate_random_interests_title"
                              data-topics='${JSON.stringify(null)}'>
                              Generate discussions
                            </button>
                          </div>`;
    }

    container.append(containerContent);

    container.removeClass('d-none');

    // Add input listener if we're in generate_your_interest mode
    if (topicCategory === 'generate_your_interest') {
      const input = container.find('.js-custom-interest-input');
      const generateButton = container.find('.js-generate-topic-titles-btn');
      const charCount = container.find('.js-char-count');

      input.on('input', function () {
        const length = $(this).val().length;
        let interestValue = $(this).val();

        charCount.text(`${length}/30 characters`);

        generateButton.prop('disabled', length === 0);

        // Update the button's data attribute with the current input
        generateButton.attr('data-topic-category', interestValue);
      });
    };
  }

  showChatTopics() {
    $('.js-main-chat-option-btn').removeClass('d-none');
    $('.js-continue-conversation-btn').removeClass('d-none');
  }

  hideChatTopics() {
    $('.js-main-chat-option-btn').addClass('d-none');
    $('.js-continue-conversation-btn').addClass('d-none');
  }

  hideTopicMenu() {
    this.chatElementContainer.classList.remove('chat-content-w-centered');
    this.chatElementContainer.classList.add('chat-content-w-default');
    this.chatElement.classList.remove('chat-content-centered');
    this.chatElement.classList.add('chat-content-default');
    if (this.chatElement.querySelector('.js-chat-intro-container')) {
      this.chatElement.querySelector('.js-chat-intro-container').remove();
    }
  }
}

export default ChatTopicService;
