import { RealtimeClient } from '@speechmatics/real-time-client';

class SpeechmaticsHandler {
  constructor(chatInput, languageCode) {
    this.chatInput = chatInput;
    this.languageCode = languageCode;
    this.client = null;
    this.finalizedText = '';
    this.currentPartialText = '';
    this.isConnected = false;
    this.isIntentionalDisconnect = false;
    this.reconnectionAttempts = 0;
    this.maxReconnectionAttempts = 5;
    this.reconnectionDelay = 2000;
    this.setupInputListener();
  }

  async setupClient(jwt) {
    if (this.isConnected) {
      return;
    }

    try {
      this.client = new RealtimeClient();

      this.setupEventListeners();

      await this.client.start(jwt, {
        transcription_config: {
          language: this.languageCode,
          enable_partials: true,
          operating_point: "enhanced",
          max_delay: 2,
          max_delay_mode: "flexible",
          diarization: "none"
        }
      });

      this.isConnected = true;
      return this.client;
    } catch (error) {
      console.error('Error starting Speechmatics client:', error);
      this.isConnected = false;
      this.handleReconnection();
      throw error;
    }
  }

  setupEventListeners() {
    this.client.addEventListener('receiveMessage', ({ data }) => {
      if (data.message === 'AddPartialTranscript') {
        this.currentPartialText = data.results
          .map(r => (r.alternatives && r.alternatives[0] ? r.alternatives[0].content : ''))
          .join(' ');
        this.updateChatInput();
      } else if (data.message === 'AddTranscript') {
        const text = data.results
          .map(r => (r.alternatives && r.alternatives[0] ? r.alternatives[0].content : ''))
          .join(' ');
        this.finalizedText = this.combineTexts(this.finalizedText, text);
        this.currentPartialText = '';
        this.updateChatInput();
      } else if (data.message === 'Error') {
        console.error('Speechmatics error:', data);
        
        // Handle specific error types
        switch (data.type) {
          case 'invalid_message':
          case 'protocol_error':
            console.error('Protocol or message error:', data.reason);
            break;
          case 'invalid_model':
          case 'invalid_config':
            console.error('Configuration error:', data.reason);
            break;
          case 'invalid_audio_type':
            console.error('Audio format error:', data.reason);
            break;
          case 'not_authorised':
            console.error('Authorization error:', data.reason);
            break;
          case 'job_error':
          case 'data_error':
          case 'buffer_error':
            console.error('Processing error:', data.reason);
            break;
          default:
            console.error('Unknown error:', data.reason);
        }

        // Only attempt reconnection for certain error types
        if (['job_error', 'buffer_error', 'data_error'].includes(data.type)) {
          this.handleDisconnection();
        } else {
          // For critical errors, mark as intentional disconnect to prevent reconnection
          this.isIntentionalDisconnect = true;
          if (this.client) {  
            this.client = null;
            this.isConnected = false;
          }
        }
      }
    });

    this.client.addEventListener('close', (event) => {
      console.log('Speechmatics WebSocket closed:', event);
      this.handleDisconnection();
    });

    this.client.addEventListener('error', (error) => {
      console.error('Speechmatics WebSocket error:', error);
      this.handleDisconnection();
    });
  }

  handleDisconnection() {
    if (this.isIntentionalDisconnect) {
      return;
    }

    this.isConnected = false;
    this.handleReconnection();
  }

  async handleReconnection() {
    if (this.isIntentionalDisconnect || this.reconnectionAttempts >= this.maxReconnectionAttempts) {
      console.error('Max reconnection attempts reached or intentional disconnect');
      return;
    }

    this.reconnectionAttempts++;
    console.log(`Attempting to reconnect to Speechmatics (Attempt ${this.reconnectionAttempts})`);

    try {
      // Get new JWT and reconnect
      const response = await fetch('/langua/speechmatics_keys', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        credentials: 'same-origin'
      });

      if (!response.ok) {
        throw new Error('Failed to get Speechmatics JWT');
      }

      const data = await response.json();
      
      // Clean up old client if it exists
      if (this.client) {
        this.client.removeAllEventListeners();
        this.client = null;
      }

      await this.setupClient(data.jwt);
      this.reconnectionAttempts = 0; // Reset counter on successful reconnection
    } catch (error) {
      console.error('Failed to reconnect:', error);
      // Exponential backoff for reconnection attempts
      const delay = this.reconnectionDelay * Math.pow(1.5, this.reconnectionAttempts - 1);
      setTimeout(() => this.handleReconnection(), delay);
    }
  }

  sendAudioChunk(audioData) {
    if (this.client && this.isConnected) {
      try {
        this.client.sendAudio(audioData);
      } catch (error) {
        console.error('Error sending audio to Speechmatics:', error);
        if (error.message.includes('Socket not ready')) {
          this.handleDisconnection();
        }
      }
    }
  }

  async finalize() {
    if (!this.client || !this.isConnected) {
      return;
    }

    return new Promise((resolve) => {
      // Set up a one-time listener for the next AddTranscript event
      const finalizeListener = ({ data }) => {
        if (data.message === 'AddTranscript') {
          console.log('Final transcript received');
          // Remove the listener after receiving the final transcript
          this.client.removeEventListener('receiveMessage', finalizeListener);
          // this.clearTranscript();
          resolve();
        }
      };

      // Add the temporary listener
      this.client.addEventListener('receiveMessage', finalizeListener);

      // Send an empty audio chunk to force processing of any buffered audio
      if (this.client) {
        console.log('Sending empty audio chunk');
        const emptyChunk = new ArrayBuffer(0);
        this.client.sendAudio(emptyChunk);
      }

      // Set a timeout to resolve the promise if no AddTranscript is received
      setTimeout(() => {
        console.log('Timeout reached, resolving promise');
        // this.clearTranscript();
        this.client.removeEventListener('receiveMessage', finalizeListener);
        resolve();
      }, 2000); // 1 second timeout
    });
  }

  async tearDown() {
    this.isIntentionalDisconnect = true;

    if (this.client) {
      try {
        await this.client.stopRecognition();
        this.client.removeAllEventListeners();
        this.client = null;
      } catch (error) {
        console.error('Error tearing down Speechmatics client:', error);
      }
    }

    this.isConnected = false;
    this.reconnectionAttempts = 0;
  }

  combineTexts(...texts) {
    const combinedText = texts
      .filter(text => text && text.trim().length > 0)
      .join(' ')
      .trim()
      // Remove spaces before punctuation
      .replace(/\s+([.,!?;:])/g, '$1')
      // Ensure space after punctuation if followed by a letter/number
      .replace(/([.,!?;:])([a-zA-Z0-9])/g, '$1 $2')
      // Remove multiple spaces
      .replace(/\s+/g, ' ');

    return combinedText;
  }

  updateChatInput() {
    this.chatInput.value = this.combineTexts(this.finalizedText, this.currentPartialText);
  }

  clearTranscript() {
    this.finalizedText = '';
    this.currentPartialText = '';
  }

  setupInputListener() {
    this.chatInput.addEventListener('input', () => {
      if (this.chatInput.value === '') {
        this.clearTranscript();
      }
    });
  }
}

export default SpeechmaticsHandler; 
