export const TRANSCRIPTION_MODES = {
  v1: {
    label: 'V1 (multilingual)',
    value: 'v1',
    model: 'whisper-1'
  },
  v2: {
    label: 'V2 (experimental, most accurate)',
    value: 'v2',
    model: 'gemini'
  },
  v3: {
    label: 'V3 (shows word by word)',
    value: 'v3',
    model: 'speechmatics'
  },
  v4: {
    label: 'V4 (gpt-4o-audio-preview)',
    value: 'v4',
    model: 'gpt-4o-audio-preview-2024-12-17'
  },
  v5: {
    label: 'V5 (Whisper Turbo)',
    value: 'v5',
    model: 'whisper-turbo'
  },
  v6: {
    label: 'V6 (Gemini Pro)',
    value: 'v6',
    model: 'gemini-pro'
  },
  v7: {
    label: 'V7 (Deepgram)',
    value: 'v7',
    model: 'deepgram'
  }
};

export const MOBILE_TRANSCRIPTION_MODES = {
  v1: {
    label: 'V1 (multilingual)',
    value: 'v1',
    model: 'whisper-1'
  },
  v2: {
    label: 'V2 (experimental, most accurate)',
    value: 'v2',
    model: 'gemini'
  },
  v3: {
    label: 'V3 (shows word by word)',
    value: 'v3',
    model: 'speechmatics'
  }
};

export const CLIENT_PROVIDERS = [
  { label: 'Anthropic', value: 'anthropic' },
  { label: 'OpenAI', value: 'openai' },
  { label: 'Azure', value: 'azure' },
  { label: 'Groq', value: 'groq' }
];

export const CHAT_MODELS = {
  anthropic: [
    { label: 'Claude 3 Opus', value: 'claude-3-opus-20240229' },
    { label: 'Claude 3 Sonnet', value: 'claude-3-sonnet-20240229' },
    { label: 'Claude 3.5 Sonnet', value: 'claude-3-5-sonnet-latest' },
    { label: 'Claude 3 Haiku', value: 'claude-3-haiku-20240307' }
  ],
  openai: [
    { label: 'GPT-4o', value: 'gpt-4o-2024-11-20' },
    { label: 'GPT-4 Turbo', value: 'gpt-4-turbo' },
    { label: 'GPT-4 Turbo Preview', value: 'gpt-4-turbo-preview' },
    { label: 'GPT-4 1106 Preview', value: 'gpt-4-1106-preview' },
    { label: 'GPT-4', value: 'gpt-4' }
  ],
  azure: [
    { label: 'GPT-4o', value: 'gpt-4o-2024-11-20' },
    { label: 'GPT-4 Turbo', value: 'gpt-4-turbo' },
    { label: 'GPT-4 Turbo Preview', value: 'gpt-4-turbo-preview' },
    { label: 'GPT-4 1106 Preview', value: 'gpt-4-1106-preview' },
    { label: 'GPT-4', value: 'gpt-4' }
  ],
  groq: [
    { label: 'Llama 3.1 405B (Preview)', value: 'llama-3.1-405b-reasoning' },
    { label: 'Llama 3.1 70B (Preview)', value: 'llama-3.1-70b-versatile' },
    { label: 'Llama 3.1 8B (Preview)', value: 'llama-3.1-8b-instant' },
    { label: 'Meta Llama 3 70B', value: 'llama3-70b-8192' }
  ]
};

export const AI_MODEL_OPTIONS = [
  { label: 'V1 (recommended, smartest)', value: 'v1' },
  { label: 'V2 (use if you see errors with V1)', value: 'v2' }
];

export const VOICE_MODEL_OPTIONS = [
  { label: 'V1 (beta model, loads faster)', value: 'v1' },
  { label: 'V2 (use for most reliable pronunciation)', value: 'v2' }
];

export function determineModel(transcriptionMode) {
  if (TRANSCRIPTION_MODES[transcriptionMode] && TRANSCRIPTION_MODES[transcriptionMode].model) {
    return TRANSCRIPTION_MODES[transcriptionMode].model;
  }
  return 'whisper-1';
}

export function isDeepgram(transcriptionMode) {
  return TRANSCRIPTION_MODES[transcriptionMode] && TRANSCRIPTION_MODES[transcriptionMode].model === 'deepgram';
}

export function isSpeechmatics(transcriptionMode) {
  return TRANSCRIPTION_MODES[transcriptionMode] && TRANSCRIPTION_MODES[transcriptionMode].model === 'speechmatics';
}
