import TranslationService from '../contents/translation_service';
import PopoverService from '../contents/popover_service';
import BottomPopoverService from '../contents/bottom_popover_service';
import ChatService from './chat/chat_service';
import ChatOptions from './chat/chat_options';
import AudioRecordingService from './chat/audio_recording_service';
import ChatTopicService from './chat/chat_topic_service';
import AudioPlayerService from './chat/audio_player_service';
import AudioPlayer from './chat/audio_player';
import TextCorrectionService from './chat/text_correction_service';
import CorrectionExplanationService from './chat/correction_explanation_service';
import ChatTranslationService from './chat/chat_translation_service';
import AlternativeResponseService from './chat/alternative_response_service';
import SuggestedReplyService from './chat/suggested_reply_service';
import SlowAudioService from './chat/slow_audio_service';
import TranslatorModalService from './chat/translator_modal_service';
import ChatFeedbackModalService from "./chat/chat_feedback_modal_service";

document.addEventListener('turbolinks:load', function() {
  const chatForm = document.getElementById('chat-form');

  if (chatForm) {
    const chatInput = document.getElementById('js-chat-input');
    const chatTopic = document.getElementById('js-chat-topic');
    const chatTopicCategory = document.getElementById('js-chat-topic-category');
    const chatSubtopicCategory = document.getElementById('js-chat-subtopic-category');

    const chatId = chatForm.dataset.contentId;
    const contentType = chatForm.dataset.contentType;
    const language = chatForm.dataset.language;
    const languageCode = chatForm.dataset.languageCode;
    const dialectCode = chatForm.dataset.dialectCode;
    const mode = chatForm.dataset.mode;
    const voice = chatForm.dataset.voice;
    const voiceProvider = chatForm.dataset.voiceProvider;
    const voiceSpeed = chatForm.dataset.voiceSpeed;
    const voiceGender = chatForm.dataset.voiceGender;
    const streamingEnabled = chatForm.dataset.streamingEnabled;
    const autoRecord = chatForm.dataset.autoRecord;
    const autoSend = chatForm.dataset.autoSend;
    const autoCorrect = chatForm.dataset.autoCorrect;
    const debug = chatForm.dataset.debug;
    const messageCount = chatForm.dataset.messageCount;
    const translationProvider = 'deepl';
    const includeContextSentence = false;
    const hideTranslationSentence = false;
    const model = chatForm.dataset.model;
    const clientProvider = chatForm.dataset.clientProvider;
    const transcriptionMode = chatForm.dataset.transcriptionMode;
    const aiModel = chatForm.dataset.aiModel;
    const voiceModel = chatForm.dataset.voiceModel;
    const premiumUser = chatForm.dataset.premiumAccount;

    const options = {
      chatId: chatId,
      language: language,
      languageCode: languageCode,
      mode: mode,
      voice: voice,
      voiceProvider: voiceProvider,
      voiceGender: voiceGender,
      voiceSpeed: voiceSpeed,
      streamingEnabled: streamingEnabled === 'true',
      autoRecord: autoRecord === 'true',
      autoSend: autoSend === 'true', 
      autoCorrect: autoCorrect === 'true', 
      debug: debug,
      messageCount: messageCount,
      model: model,
      clientProvider: clientProvider,
      transcriptionMode: transcriptionMode,
      aiModel: aiModel,
      dialectCode: dialectCode,
      voiceModel: voiceModel,
      beta_features_enabled: chatForm.dataset.betaFeaturesEnabled
    };
    
    const chatOptions = new ChatOptions(options);

    // Audio recording variables
    const chatElementContainer = document.querySelector('.chat-content-w');
    const chatElement = document.querySelector('.chat-content');
    const chatControls = document.querySelector('.chat-controls');
    const chatSubmitBtn = document.getElementById('js-chat-submit-btn');
    const recordBtn = document.getElementById("js-record-query-btn"); // Make sure this ID matches your HTML button's ID
    const pauseBtn = document.getElementById("js-pause-recording-btn"); // Make sure this ID matches your HTML button's ID
    const micIcon = document.querySelector(".fa.fa-microphone"); // Updated class selector

    const csrfToken = $('meta[name="csrf-token"]').attr('content');
    const premiumAccount = premiumUser;

    const translationService = new TranslationService(translationProvider, language, includeContextSentence, csrfToken);

    const popoverService = new BottomPopoverService(translationService, language, chatId, contentType, csrfToken, 'true', hideTranslationSentence);

    const audioPlayer = new AudioPlayer();
    const audioPlayerService = new AudioPlayerService(csrfToken);
    const chatTopicService = new ChatTopicService(chatElementContainer, chatElement);
    const textCorrectionService = new TextCorrectionService(csrfToken);
    const correctionExplanationService = new CorrectionExplanationService(csrfToken);
    const chatTranslationService = new ChatTranslationService(csrfToken);
    const alternativeResponseService = new AlternativeResponseService(csrfToken);
    const suggestedReplyService = new SuggestedReplyService(csrfToken, dialectCode);
    const slowAudioService = new SlowAudioService(csrfToken);
    const feedbackModalService = new ChatFeedbackModalService(csrfToken);
    const translatorModalService = new TranslatorModalService(csrfToken);
    const chatService = new ChatService(chatId, chatOptions, chatElementContainer, chatElement, chatControls, chatForm, chatInput, chatTopicCategory, chatSubtopicCategory, chatTopic, chatSubmitBtn, popoverService, translationService, audioPlayerService, chatTopicService, textCorrectionService, correctionExplanationService, chatTranslationService, alternativeResponseService, suggestedReplyService, slowAudioService, translatorModalService, feedbackModalService);
    chatService.audioPlayer = audioPlayer;
    const audioRecordingService = new AudioRecordingService(csrfToken, chatService, audioPlayerService, recordBtn, micIcon, pauseBtn);

    if (chatSubmitBtn) {
      chatService.init();
    } else {
      chatService.setupEventListeners();
    }

    if (recordBtn) {
      audioRecordingService.setup();

      import(/* webpackChunkName: "nosleep" */ 'nosleep.js').then(module => {
        const NoSleep = module.default;
        const noSleep = new NoSleep();

        // To enable wake lock:
        // // (must be wrapped in a user input event handler e.g. a mouse or touch handler)
        document.addEventListener('click', function enableNoSleep() {
          document.removeEventListener('click', enableNoSleep, false);
          noSleep.enable();
        }, false);
      });
    }

    chatOptions.audioRecordingService = audioRecordingService;
    chatOptions.audioPlayerService = audioPlayerService;
  }
});
