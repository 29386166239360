import ChatTopicService from './chat/chat_topic_service';
import consumer from "../channels/consumer"


document.addEventListener('turbolinks:load', function() {
  const chatForm = document.getElementById('new-chat-form');

  if (chatForm) {
    const chatTopic = document.getElementById('js-chat-topic');
    const chatTopicCategory = document.getElementById('js-chat-topic-category');
    const chatSubtopicCategory = document.getElementById('js-chat-subtopic-category');
    const continueConversationBtn = document.querySelector('.js-continue-conversation-btn');

    const chatModeButtons = document.querySelectorAll('.js-chat-mode-radio-label');
    const chatVoiceOptionsEl = document.querySelectorAll('.chat-voice-options-container');
    const chatVoiceSelect = document.getElementById('chat_form_voice');
    let chatMode = document.querySelector('.js-chat-mode-radio-label.active').innerText;
    let chatVoice = chatVoiceSelect.options[chatVoiceSelect.selectedIndex].label;
    
    const chatElementContainer = document.querySelector('.chat-content-w');
    const chatElement = document.querySelector('.chat-content');

    const chatTopicService = new ChatTopicService(chatElementContainer, chatElement);

    $('body').on('click', '.js-chat-options-btn', function (e) {
      $('#chatOptionsModal').modal('show');
    });

    // Event listener for main category buttons (e.g., 'Roleplay')
    $(document).on('click', '.js-main-chat-option-btn', function(event) {
      event.preventDefault();

      if (continueConversationBtn) {
        continueConversationBtn.classList.add('d-none');
      }

      const topicCategory = $(this).data('topic-category');
      const subOptionsContainer = $('.js-sub-options-container');
      const categories = $(this).data('topics');

      chatTopicService.showSubCategories(topicCategory, categories, subOptionsContainer);
    });

    // Event listener for subcategory buttons within a main category
    $(document).on('click', '.js-sub-category-btn', function(event) {
      event.preventDefault();

      const topicCategory = $(this).data('topic-category');
      const topicsData = $(this).data('topics');
      const subtopicCategory = $(this).data('subtopic-category');
      const vocabSubtopic = (topicCategory === 'vocab' && subtopicCategory === 'practice_my_vocab_blend_into_chat') ||
          (topicCategory === 'vocab' && subtopicCategory === 'practice_my_vocab_word_by_word');
      const chatAboutAnythingSubtopic = (topicCategory === 'chat_about_anything' && subtopicCategory === 'my_life');

      if (topicCategory === 'my_interests' || subtopicCategory === 'my_interests') {
        const subOptionsContainer = $('.js-sub-options-container');

        if (topicsData === null || subtopicCategory === 'generated_interest') {
          if (subtopicCategory === 'generate_your_interest') {
            chatTopicService.addCategoryContent('generate_your_interest', subOptionsContainer)
          } else if (subtopicCategory === 'generated_interest') {
            chatTopicService.addCategoryContent('generated_interest', subOptionsContainer, topicsData[0].key)
          } else {
            chatTopicService.addCategoryContent('my_interests', subOptionsContainer)
          }
        } else {
          chatTopicService.showSubCategories(subtopicCategory, topicsData, subOptionsContainer);
        }
      } else if (topicCategory === 'roleplay' || vocabSubtopic || chatAboutAnythingSubtopic) {
        chatTopicService.showSubOptions(topicCategory, subtopicCategory, topicsData);
      } else {
        chatTopicCategory.value = topicCategory;
        chatTopic.value = subtopicCategory;
        chatForm.submit();
      }
    });

    // Event listener for final topic selection
    $(document).on('click', '.js-sub-chat-option-btn', function(event) {
      event.preventDefault();

      const topic = $(this).data('topic');
      const topicCategory = $(this).data('topic-category');
      const subtopicCategory = $(this).data('subtopic-category');

      chatTopicCategory.value = topicCategory;
      chatSubtopicCategory.value = subtopicCategory;
      chatTopic.value = topic;
      chatForm.submit();
    });

    $(document).on('click', '.js-generate-topic-titles-btn', function(event) {
      event.preventDefault();
      
      const parentDiv = $(this).closest('.col-12');
      const button = parentDiv.find('button');
      const topicCategory = button.data('topic-category') || this.dataset.topicCategory;

      $(this).text('Loading...').prop('disabled', true);
      
      consumer.subscriptions.create({ channel: "ChatInterestTitlesChannel", interest: topicCategory }, {
        received: (data) => {
          if (data.completed) {
            if (data.error) {
              console.error('Error generating titles:', data.error);
            } else {
              const container = parentDiv.parent();
              const generateButtonDiv = $('.js-generate-topic-titles-btn').closest('.col-12');
              generateButtonDiv.remove();
              
              data.topics.forEach(topic => {
                const divHtml = $(`<div class="col-12 col-md-4 col-lg-4 col-xl-3"></div>`);
                const buttonHtml = $(`<button type="button" 
                  class="btn btn-secondary-cta-outline lightgray-bg py-3 chat-option-button w-100 mb-2 js-sub-category-btn" 
                  data-topic-category="${topicCategory}" 
                  data-subtopic-category=${JSON.stringify(topic.key || '')}
                  data-topics=''>${topic.label}</button>`);
                
                divHtml.append(buttonHtml);
                container.append(divHtml);
                $('.js-sub-options-container').css({'overflow-y': 'auto', 'max-height': '70vh'});
              });
            }
          }
        }
      });

      $.ajax({
        url: '/langua/chat_interest_titles',
        method: 'POST',
        data: {
          interest: topicCategory
        },
        error: (xhr, status, error) => {
          console.error('Error starting title generation:', error);
        }
      });
    });

    chatModeButtons.forEach(function(button) {
      button.addEventListener('click', function(e) {

        // Remove active class from all buttons
        chatModeButtons.forEach(function(btn) { btn.classList.remove('active'); });

        // Add active class to the clicked button
        this.classList.add('active');

        // Ensure the associated radio button is checked
        var inputElement = this.previousElementSibling;
        inputElement.checked = true;

        // Get input value from input above the label
        var modeValue = inputElement.value;

        switch(modeValue) {
          case 'text_only':
            chatVoiceOptionsEl.forEach(el => el.classList.add('d-none'));
            break;
          case 'audio_only':
            chatVoiceOptionsEl.forEach(el => el.classList.remove('d-none'));
            break;
          case 'text_audio':
            chatVoiceOptionsEl.forEach(el => el.classList.remove('d-none'));
            break;
        }

        // Set chat mode to be button text
        chatMode = e.target.innerText;
        updateChatOptionsDisplay();
      });
    });

    chatVoiceSelect.addEventListener('change', function(e) {
      // set chat voice to be the label of the selected option
      chatVoice = e.target.options[e.target.selectedIndex].label;
      updateChatOptionsDisplay();
    });

    function updateChatOptionsDisplay() {
      if (chatMode === 'Text only') {
        $('.js-display-chat-options').text(`You will be chatting in ${chatMode} mode`);
      } else if (chatVoice) {
        $('.js-display-chat-options').text(`You will be chatting with ${chatVoice} in ${chatMode} mode`);
      }
    }
  }
});
